import { Cart, CartItem, Carts } from "../../shared/model/types";
import ProductService from "../../shared/services/products-service";

export const addNewCart = (setCarts: React.Dispatch<React.SetStateAction<Carts>>, cartId: string, newCart: Cart) => {
  setCarts((prevCarts: any) => ({
    ...prevCarts,
    [cartId]: newCart,
  }));
};

export const addItemToCart = (
  setCarts: React.Dispatch<React.SetStateAction<Carts>>,
  cartId: string,
  newItemData: Omit<CartItem, "itemID" | "stock"> // Exclude itemID and stock from the input
) => {
  setCarts((prevCarts) => {
    const currentCart = prevCarts[cartId];

    if (!currentCart) {
      throw new Error(`Cart with ID ${cartId} not found`);
    }

    const currentItems = currentCart.items ?? [];
    // TODO: re-enable code if stock information is enabled again
    // Check if the item already exists in the cart based on `type`, `gtin`, and `additional_product_gtin`
    // const existingItemIndex = currentItems.findIndex(
    //   (item) =>
    //     item.type === newItemData.type &&
    //     item.gtin === newItemData.gtin &&
    //     item.additional_product_gtin === newItemData.additional_product_gtin
    // );

    // if (existingItemIndex !== -1) {
    //   // If the item exists, increase its stock
    //   const updatedItems = currentItems.map((item, index) =>
    //     index === existingItemIndex
    //       ? { ...item, stock: item.stock + 1 } // Increase stock by 1
    //       : item
    //   );

    //   return {
    //     ...prevCarts,
    //     [cartId]: {
    //       ...currentCart,
    //       items: updatedItems,
    //     },
    //   };
    // } else {
    // If the item does not exist, add it with a stock of 1
    const newItemID = currentItems.length + 1;
    const newItem: CartItem = {
      ...newItemData,
      itemID: newItemID,
      stock: 1, // Set default stock to 1
    };

    return {
      ...prevCarts,
      [cartId]: {
        ...currentCart,
        items: [...currentItems, newItem],
      },
    };
    // }
  });
};

export const removeItemFromCart = async (setCarts: React.Dispatch<React.SetStateAction<Carts>>, cartId: string, itemId: number) => {
  setCarts((prevCarts) => {
    const currentCart = prevCarts[cartId];

    if (!currentCart) {
      throw new Error(`Cart with ID ${cartId} not found`);
    }

    // Find item to remove
    const itemToRemove = currentCart.items?.find((item) => item.itemID === itemId);

    // if cart type is "elten-ortho" & item type is "shoe"
    const shouldRemoveAdaptions = currentCart.type === "elten-ortho" && itemToRemove?.type === "shoe";

    // Filter items
    const updatedItems =
      currentCart.items?.filter((item) => {
        if (item.itemID === itemId) return false;
        if (shouldRemoveAdaptions && item.type === "shoe-adaption") return false;
        return true;
      }) ?? [];

    // Check if cart should be deleted
    if (updatedItems.length === 0) {
      // Delete cart using ProductService
      ProductService.deleteCart(cartId).catch((error) => console.error(`Failed to delete cart with ID ${cartId}:`, error));

      // Return the updated carts without the empty cart
      const { [cartId]: _, ...remainingCarts } = prevCarts;
      return remainingCarts;
    }

    // Update the cart with the filtered items if items > 0
    return {
      ...prevCarts,
      [cartId]: {
        ...currentCart,
        items: updatedItems,
      },
    };
  });
};

export const deleteCarts = (carts: Carts) => {
  carts &&
    Object.keys(carts).map((cartId: string) => {
      ProductService.deleteCart(cartId).catch((error) => console.error(`Failed to delete cart with ID ${cartId}:`, error));
    });
};

export const updateItemInCart = (setCarts: React.Dispatch<React.SetStateAction<Carts>>, cartId: string, updatedItem: CartItem) => {
  setCarts((prevCarts: any) => ({
    ...prevCarts,
    [cartId]: {
      ...prevCarts[cartId],
      items: prevCarts[cartId].items.map((item) => (item.appliances_id === updatedItem.appliances_id ? updatedItem : item)),
    },
  }));
};

export const removeCart = (setCarts: React.Dispatch<React.SetStateAction<Carts>>, cartId: string) => {
  setCarts((prevCarts) => {
    const { [cartId]: _, ...remainingCarts } = prevCarts;
    return remainingCarts;
  });
};

export const updateCartInfo = (setCarts: React.Dispatch<React.SetStateAction<Carts>>, cartId: string, updatedInfo: Partial<Cart>) => {
  setCarts((prevCarts) => ({
    ...prevCarts,
    [cartId]: {
      ...prevCarts[cartId],
      ...updatedInfo, // Merge updated info
    },
  }));
};

export const getStandardCartId = (carts: Carts) => {
  for (const [cartId, cart] of Object.entries(carts)) {
    if (cart.type === "standard") {
      return cartId;
    }
  }
  // return undefined; // return undefined if no cart with type 'standard' is found
};

export const updateItemStock = (
  setCarts: React.Dispatch<React.SetStateAction<Carts>>,
  cartId: string,
  itemId: number,
  change: number // Positive for increase, negative for decrease
) => {
  setCarts((prevCarts) => {
    const currentCart = prevCarts[cartId];

    if (!currentCart) {
      throw new Error(`Cart with ID ${cartId} not found`);
    }

    const updatedItems = currentCart.items.map((item) => {
      // Find the item by itemID
      if (item.itemID === itemId) {
        // Adjust the item's stock based on the change
        const newStock = item.stock + change;

        // Ensure stock never goes below 1 (or 0 if allowed)
        return {
          ...item,
          stock: Math.max(newStock, 1), // use 0 if you want to allow removing
        };
      }
      return item;
    });

    return {
      ...prevCarts,
      [cartId]: {
        ...currentCart,
        items: updatedItems,
      },
    };
  });
};

export const doesCartTypeExist = (carts: Carts, cartType: string): Cart[] => {
  const matchingCarts: Cart[] = [];
  for (const cartId in carts) {
    if (carts[cartId].type === cartType) {
      matchingCarts.push(carts[cartId]); // Add the matching cart to the array
    }
  }
  return matchingCarts; // Return all matching carts
};


export const getCartIdByType = (carts: Carts, cartType: string): string => {
  for (const cartId in carts) {
    if (carts[cartId].type === cartType) {
      return cartId; // Return the cart ID if the type matches
    }
  }
};

// Function to find the product name by size
export const findProductBySize = (data: any, sizeValue: any) => {
  for (let category in data) {
    for (let productType in data[category]) {
      const product = data[category][productType];
      if (Object.values(product.sizes).includes(sizeValue)) {
        return product.name;
      }
    }
  }
  // return null;
};

// Function to find the product image by size
export const findProductImageBySize = (data: any, sizeValue: any) => {
  for (let category in data) {
    for (let productType in data[category]) {
      const product = data[category][productType];
      if (Object.values(product.sizes).includes(sizeValue)) {
        return product.image;
      }
    }
  }
  // return null;
};
