import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, ImageBackground, TextInput } from "react-native";
import { useNavigation } from "@react-navigation/native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { background, Icons } from "../../assets";
import { Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import ProductService from "../../shared/services/products-service";
import TextTitle from "../../components/Text/TextTitle";
import { addItemToCart, addNewCart, doesCartTypeExist, getCartIdByType, updateCartInfo } from "../Order/cartFunctions";
import { Cart } from "../../shared/model/types";

const OrthopedicInsoleOptionsModal: React.FC = (): JSX.Element => {
  // State variables
  const [Colors] = useGlobalState("colors");
  const [checkBoxValue, setCheckBoxValue] = useState<boolean>(false);
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const navigation = useNavigation();
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [currentScan] = useGlobalState("currentScan");
  const [customerStoreID] = useGlobalState("customerStoreID");
  const [selectedShoe] = useGlobalState("selectedShoe");
  const [carts, setCarts] = useGlobalState("carts");
  const [industryPartner] = useGlobalState("industryPartner");
  const [leftTextInput, setLeftTextInput] = useState("");
  const [rightTextInput, setRightTextInput] = useState("");

  const handleSubmission = () => {
    const cartData = {
      // TODO: change this to a variable when it is clarified
      type: "elten-ortho",
      industry_partner: industryPartner && industryPartner.store_id || null,
      store_id: industryPartner.store_id || customerStoreID || currentScanner.storeid,
      ...(currentUser.employeeNumber ? { employee_number: currentUser.employeeNumber } : { anonymous_user_uuid: currentScan.customer_uuid }),
      items: [],
    };
    let insoleAppliancesID: string;
    let cartItemData = {
      type: "orthopedic-insole",
      additional_product_gtin: "4030839367087",
      comments: `Schuhe GTIN: ${selectedShoe.gtin}, Links: ${leftTextInput} , Rechts: ${rightTextInput}`,
      itemName: "Orthopedic Insole",
      itemIcon: Icons.soleIcon,
      store_id: industryPartner.store_id || customerStoreID || currentScanner.storeid,
    };
    const cart: Cart[] = doesCartTypeExist(carts, "elten-ortho")
    if (cart.length <= 0) {
      ProductService.createCart(cartData).then((cart: any) => {
        addNewCart(setCarts, cart.uuid, cartData);
        updateCartInfo(setCarts, cart.uuid, { orderNr: cart.order_nr });
        if (checkBoxValue) {
          addItemToCart(setCarts, cart.uuid, cartItemData);
          selectedShoe.suitableOrthoAdaption
            ? navigation.navigate("OrthopedicShoesOptionsModal", {
                cartUUID: cart.uuid,
                orthoInsoleAdded: checkBoxValue,
                insoleAppliancesID: insoleAppliancesID,
              })
            : navigation.navigate("CartModal");
        } else if (selectedShoe.suitableOrthoAdaption) {
          navigation.navigate("OrthopedicShoesOptionsModal", {
            cartUUID: cart.uuid,
            orthoInsoleAdded: checkBoxValue,
            insoleAppliancesID: null,
          });
        } else {
          navigation.navigate("CartModal");
        }
      });
    } else if (cart.length > 0) {
      let isCartCreated = false;

      cart.forEach((orthoCart: Cart) => {
        if (orthoCart.industry_partner === industryPartner.store_id) {
          isCartCreated = true; // Cart already exists for the store
        }
      });

      if (!isCartCreated) {
        ProductService.createCart(cartData).then((newCart: any) => {
          addNewCart(setCarts, newCart.uuid, cartData);
          updateCartInfo(setCarts, newCart.uuid, { orderNr: newCart.order_nr });

          if (checkBoxValue) {
            addItemToCart(setCarts, newCart.uuid, cartItemData);
            if (selectedShoe.suitableOrthoAdaption) {
              navigation.navigate("OrthopedicShoesOptionsModal", {
                cartUUID: newCart.uuid,
                orthoInsoleAdded: checkBoxValue,
                insoleAppliancesID: insoleAppliancesID,
              });
            } else {
              navigation.navigate("CartModal");
            }
          } else if (selectedShoe.suitableOrthoAdaption) {
            navigation.navigate("OrthopedicShoesOptionsModal", {
              cartUUID: newCart.uuid,
              orthoInsoleAdded: checkBoxValue,
              insoleAppliancesID: null,
            });
          } else {
            navigation.navigate("CartModal");
          }
        });
      } else {
        const cartUUID = getCartIdByType(carts, "elten-ortho");
      if (checkBoxValue) {
        addItemToCart(setCarts, cartUUID, cartItemData);
        selectedShoe.suitableOrthoAdaption
          ? navigation.navigate("OrthopedicShoesOptionsModal", {
              cartUUID: cartUUID,
              orthoInsoleAdded: checkBoxValue,
              insoleAppliancesID: insoleAppliancesID,
            })
          : navigation.navigate("CartModal");
      } else if (selectedShoe.suitableOrthoAdaption) {
        navigation.navigate("OrthopedicShoesOptionsModal", {
          cartUUID: cartUUID,
          orthoInsoleAdded: checkBoxValue,
          insoleAppliancesID: null,
        });
      } else {
        navigation.navigate("CartModal");
      }
      }
    } 
    // else if (cart && cart.industry_partner === industryPartner.store_id) {
    //   const cartUUID = getCartIdByType(carts, "elten-ortho");
    //   if (checkBoxValue) {
    //     addItemToCart(setCarts, cartUUID, cartItemData);
    //     selectedShoe.suitableOrthoAdaption
    //       ? navigation.navigate("OrthopedicShoesOptionsModal", {
    //           cartUUID: cartUUID,
    //           orthoInsoleAdded: checkBoxValue,
    //           insoleAppliancesID: insoleAppliancesID,
    //         })
    //       : navigation.navigate("CartModal");
    //   } else if (selectedShoe.suitableOrthoAdaption) {
    //     navigation.navigate("OrthopedicShoesOptionsModal", {
    //       cartUUID: cartUUID,
    //       orthoInsoleAdded: checkBoxValue,
    //       insoleAppliancesID: null,
    //     });
    //   } else {
    //     navigation.navigate("CartModal");
    //   }
    // }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true}>
        <View style={styles.modalWrapper}>
          <View style={styles.formWrapper}>
            <Text style={[styles.titleText, { marginBottom: "3%" }]}>{i18n.t("orthopedicInsole")}</Text>
            <View style={styles.optionsViewWrapper}>
              <View style={styles.infoView}>
                <View style={styles.inputsWrapper}>
                  <TextTitle text={i18n.t("orthopedicInsole")} />
                </View>
              </View>
              <View style={styles.checkboxView}>
                <BouncyCheckbox
                  textComponent
                  innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                  fillColor={Colors.mainColorLight.color}
                  isChecked={checkBoxValue}
                  onPress={() => setCheckBoxValue(!checkBoxValue)}
                  size={45}
                />
              </View>
              <View style={styles.optionsView}>
                <View style={styles.optionsSideWrapper}>
                  <Text style={styles.titleText}>{i18n.t("scan.left")}</Text>
                  <Text style={styles.titleText}>{i18n.t("scan.right")}</Text>
                </View>
                <View style={styles.optionsSideCommentsWrapper}>
                  <View style={[styles.inputsWrapper, { width: "40%", height: "50%" }]}>
                    <TextInput onChangeText={(text) => setLeftTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                  </View>
                  <View style={[styles.inputsWrapper, { width: "40%", height: "50%" }]}>
                    <TextInput onChangeText={(text) => setRightTextInput(text)} style={styles.textInput} placeholder={i18n.t("comments")} secureTextEntry={false} autoCorrect={false} />
                  </View>
                </View>
              </View>
            </View>
            <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => handleSubmission()}>
              <Text style={styles.buttonText}>{i18n.t("next")}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  closeButton: {
    width: "7%",
    height: "12%",
    position: "absolute",
    right: -5,
    top: 0,
    zIndex: 10,
    paddingTop: "3.5%",
    paddingLeft: "2%",
  },
  iconContainer: {
    width: "100%",
    height: "100%",
    resizeMode: "contain",
  },
  modalWrapper: {
    flex: 1,
    width: "65%",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "1.6%",
    alignSelf: "center",
  },
  formWrapper: {
    width: "95%",
    height: "95%",
    padding: "2%",
    alignSelf: "center",
    backgroundColor: "white",
  },
  inputsWrapper: {
    width: "95%",
    height: "20%",
    marginTop: "2%",
    paddingLeft: "1%",
    paddingRight: "1%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    alignSelf: "center",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "flex-end",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
  error: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
    color: "red",
    marginTop: "4%",
  },
  optionsViewWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "85%",
  },
  infoView: {
    width: "25%",
    height: "65%",
  },
  textInput: {
    width: "100%",
    height: "99%",
  },
  checkboxView: {
    width: "10%",
    height: "100%",
    alignItems: "center",
    marginTop: "20%",
  },
  optionsView: {
    width: "65%",
    height: "100%",
    display: "flex",
  },
  optionsSideWrapper: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  optionsSideCommentsWrapper: {
    width: "100%",
    height: "50%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    marginTop: "-5%",
  },
});

export default OrthopedicInsoleOptionsModal;
