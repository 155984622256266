export default {
  'onefid': 'ONEFID',
  'telephone': '+ 49 221 95793412',
  'how to use': 'ANLEITUNG',
  'customer support': 'KUNDEN SUPPORT',
  'catalog': 'Katalog',
  'start': 'START',
  'back': 'ZURÜCK',
  'goBack': 'ZURÜCK',
  'next': 'WEITER',
  'new scan': 'NEUER SCAN',
  'home': 'HOME',
  'no scanner found': 'Keine 3D Scanner gefunden.',
  'detect scanner message': 'Suche nach 3D Scannern',
  'yes': 'JA',
  'no': 'NEIN',
  'measurements': 'Messungen',
  'analysis': 'Analyse',
  'more': 'Mehr',
  'new user': 'Neuer Benutzer',
  'new scan modal logged in user': 'Dieser Benutzer ist angemeldet\nWillst Du einen neuen Scan anlegen\noder Dich ausloggen?',
  'new scan modal not logged in user': '"NEUR SCAN" wurde gewählt\ndies überschreibt die momentanen Ergebnisse.\nWie willst du fortfahren?',
  'save result': 'Ergnisse speichern',
  'weight': 'Gewicht',
  'activity': 'Aktivität',
  'searchPlaceHolder': 'Was suchst Du?',
  'loadProductMessage': 'Bitte warten Sie während Ihr individueller Produktkatalog geladen wird. Dies kann einen Moment dauern...',
  'noProductFoundMessage': 'Sorry, leider konnten wir für Deine Größe keine passenden Produkte finden!',
  'noSearchProductFoundMessage': 'Sorry, für diese Scuhe wurden leider keine Produkte gefunden!',
  'resetFilter': 'Filter zurücksetzen',
  'loadMoreProducts': 'Lade mehr Produkte!',
  'make new search': 'Neue Suche',
  'In stock': 'Auf Lager',
  'projectedStock': 'Erwarteter Bestand',
  'out of stock': 'nicht vorrättig',
  'SAVE': 'Speichern',
  'FEEDBACK': 'FEEDBACK',
  'order now': 'JETZT BESTELLEN',
  'price': 'Preis',
  'noInternet': 'Wir konnten keine Internetverbindung finden. Bitte überprüfen',
  'moreDetails':'mehr Details',
  'recommendedForYou':'EMPFOHLEN FÜR  DICH',
  'addItem':'Einlage hinzufügen',
  'mainScreen': 'Main Screen',
  'createInsole':'Insole Erstellen',
  'insoleCreation': 'Die Erstellung der Einlegesohle ist in Arbeit',
  'shoeSize': 'Größe',
  'insoleType': 'Einlagentyp',
  'recommendedInsole': 'Empfohlene Einlegesohle: ',
  'sport': 'Sport',
  'everyday': 'Alltag',
  "chooseCustomer": "FIRMENKUNDE WÄHLEN",
  "chooseButton": "WÄHLEN",
  "adjustSize": "GRÖßE ANPASSEN",
  "comments": "Kommentare",
  "createProcess": "Vorgang anlegen",
  "legLengthCompensation1,5cm": "BEINLÄNGENAUSGLEICH BIS 1,5 CM",
  "legLengthCompensation3cm": "BEINLÄNGENAUSGLEICH BIS 3 CM",
  "inOutEdgeElevation": "INNEN-/AUßENRANDERHÖUNG ",
  "corkWedge": "KORKKEIL",
  "haglundFerse": "HAGLUNDFERSE",
  "wheelingSole": "ABROLLSOHLE",
  "butterflyRoll": "SCHMETTERLINGSROLLE",
  "orthoOrderSuccessMessage": "Wir haben Ihre orthopädischen\n Schuh- und Einlagenanpassungen erfolgreich gespeichert",
  "orthopedicInsole": "ORTHOPÄDISCHE EINLAGE",
  "changeSizeButton": "Größe auswählen",
  "shoeAdaptionsModalTitle": "Zurichtungen",
  "listOfSizes": "Liste der Größen",
  "orthoOrder": "Ortho Bestellung",
  "shoeFitting": "SCHUHZURICHTUNG",
  "fullCatalog": "ELTEN GESAMTKATALOG",
  "accessoriesCatalogTitle": "Zubehör",
  "repeatScanButton": "Scan wiederholen",
  "items": "Artikel",
  "removeButton": "entfernen",
  "checkoutButton": "Abschicken",
  "cartIsEmpty": "Warenkorb ist leer",
  "orthoInsolePopupText": "Pro Bestellung ist nur eine orthopädische Einlage zulässig.",
  "closeButton": "schließen",
  "customInsole": "Individuelle Einlegesohle",
  "itemsInCartWarning": "Es sind noch Artikel im Warenkorb der nicht abgeschlossen ist.\nTrotzdem fortfahren?",
  "cartButton": "Warenkorb",
  "white": "Weiß",
  "black": "Schwarz",

  newScan: {
    headlines: {
      gender: 'Geschlecht',
      mode: 'Modus',
      confirmation: 'Bestätigen'
    },
    'origin':'REGION',
    'birthdate':'GEBURTSJAHR',
    'birthdateQuestion':'Bitte gib Dein Geburtsjahr an'
  },
  gender: {
    caption: 'Geschlecht',
    female: 'weiblich',
    male: 'männlich'
  },
  mode: {
    caption: 'Modus',
    barefoot: 'Barfuß',
    socks: 'Socken',
    dark_socks: 'dunkel Socken',
    bright_socks: 'hell Socken'
  },
  auth: {
    login: 'Anmelden',
    register: 'Registrieren',
    confirm: 'Bestätigen',
    connect: 'Verbinden',
    createAccount: 'Konto erstellen',
    logIn: 'Anmelden',
    logOut: 'Abmelden',
    addEmail: 'E-Mail hinzufügen',
    changeEmail: 'E-Mail ändern',
    stayConnected: 'verbunden bleiben',
    logoutMessage: 'Dieser Benutzer is angemeldet\nAbmelden?',
    passwordPlaceholder: 'Bitte gib Dein Paswort ein',
    emailPlaceholder: 'Bitte gib Deine Email ein',
    employeenumberPlaceholder: 'Geben Sie die Nummer Ihres Mitarbeiters einr',
    emailRequired: 'Email Adresse ist erforderlich.',
    emailInvalid: 'Email Adress ist ungültig.',
    passwordRequired: 'Passwort ist erforderlic.',
    firstNameRequired: 'Vorname ist erforderlich.',
    lastNameRequired: 'Nachname ist erforderlich.',
    dateOfBirthRequired: 'Geburtsdatum ist erforderlich.',
    employeeNumberRequired: 'Mitarbeiter-Nummer ist erforderlich.',
    unableToLogin: 'Mit den angegebenen Daten ist das Anmelden nicht möglich',
    emailExists: 'Einen Benutzer mit dieser Emial Adresse gibt es schon.',
    employeeNumberExists: 'Es existiert ein Benutzer mit der gleichen Mitarbeiternummer.',
    unique: 'Einen Benutzer mit dieser Emial Adresse gibt es schon.',
    confirmEmailAddedMessage:'Ihre E-Mail wurde hinzugefügt',
    addEmailInfo: 'Fügen Sie Ihre E-Mail-Adresse hinzu, um auf Ihre Daten auf allen unterstützten Plattformen zuzugreifen',
    emailAddedInstructions: 'Sie werden in Kürze eine E-Mail erhalten, um ein Passwort festzulegen.',
    provideAllFields: 'Bitte füllen Sie alle Felder aus',
    genericError: 'Es ist ein Fehler aufgetreten, bitte versuchen Sie es in einigen Augenblicken erneut.',
    succesRegisterMessage: [
      'Wir haben Deine\nOne',
      'Fitting ID',
      'erfolgreich gespeichert'
    ],
    shoepassionSuccesRegisterMessage: [
      'Wir haben Deine ',
      'ShoepassionID',
      'erfolgreich gespeichert'
    ],
    confirmRegisterMessage: [
      'Deine',
      'One Fitting ID',
      'wurde erfolgreich gespeichert! Klick auf den Link den wir Dir gesendet haben,',
      'um sie überall zu verwenden'
    ],
    shoepassionConfirmRegisterMessage:[
      'Deine',
      'ShoepassionID',
      'wurde erfolgreich gespeichert! Klick auf den Link den wir Dir gesendet haben,',
      'um sie überall zu verwenden'
    ],
    termsAndConditions: ['Ich habe die Datenschutzbestimmungen gelesen und stimme der Nutzung meiner Daten zu', 'Datenschutzbestimmungen'],
    oneFittingIDCreation: 'One Fitting ID erstellen',
    shoepassionNewsletter: ['Für Shoepassion Newsletter anmelden und Datennutzung laut', 'zustimmen']
  },
  support: {
    caption: 'Kontakt',
    selectScanner: 'Scanner auswählen',
    calibration: 'Kalibrierung',
    form: {
      textPlaceholder: 'Beschreibe das Problem',
      namePlaceholder: 'Name',
      sendButtonText: 'Senden'
    },
    bottomCards: {
      customerSupport: {
        caption: 'Kunden Support',
        phoneNumber: 'OneFID: + 49 221 95793412',
        emailAddress: 'scanner-support@onefid.com'
      },
      scannerId: {
        caption: 'Scanner ID'
      }
    }
  },
  scan: {
    captionLeft: 'BITTE STELLE DEINEN LINKEN FUẞ AUF SCANNER',
    succescaptionLeft: 'LINKER FUẞ GESCANNT',
    captionRight: 'BITTE STELLE DEINEN RECHTEN FUẞ AUF SCANNER',
    succescaptionRight: 'RECHTER FUẞ GESCANNT',
    captionMulti: 'BITTE STELLE DICH AUF DEN SCANNER',
    succescaptionMulti: 'BEIDE FÜSSE GESCANNT',
    start: 'START',
    left: 'LINKS',
    right: 'RECHTS',
    both: 'BEIDE',
    inProgress: 'DEINE FÜẞE WERDEN GESCANNT',
    setParameter: 'KAMERA WIRD VORBEREITET',
    capture: 'FÜSSE WERDEN GESCANNT',
    process: '3D SCAN WIRD VERARBEITET',
    succesScanMessage: [
      'SCAN ERFOLGREICH',
      'Wir erstellen Deinen One',
      'FID',
      'Scan',
      'Dein Scan wird in wenigen Sekunden fertig sein!'
    ],
    faildScanMessage: [
      'SCAN FEHLGESCHLAGEN',
      'Es gab ein problem mit Deinem One',
      'FID',
      'Scan.',
      'Bitte versuche es erneut.\nBitte kontaktiere den Kunden Support wenn das Problem weiter besteht.',
      'NOCHMAL SCANNEN',
      'ABBRECHEN'
    ],
    error: {
      title: 'Da hat leider etwas nicht funktioniert.',
      callSupport: 'Sollte das das Problem nicht beheben, bitte an den Support wenden.',
      genericError: 'Es ist ein Fehler aufgetreten.',
      scannerBusy: 'Scanner ist beschäftigt, bitte scannen Sie in einigen Sekunden erneut.',
      foamBoxNotImplemented: 'Es ist ein Fehler aufgetreten.',
      log_output_too_short: 'Es ist ein Fehler aufgetreten.',
      an_error_occured: 'Es ist ein Fehler aufgetreten.',
      make_missing_target: 'Bitte an den Support wenden.',
      incompatible_configuration_file: 'Bitte an den Support wenden.',
      light_hardware_not_accessible: 'Bitte den Scanner ausschalten und nach zehn Sekunden wieder einschalten. Dann die Messung wiederholen.',
      camera_initialization_fails: 'Es ist ein Sensor-Fehler aufgetreten. Bitte den Reset des Scanners abwarten und dann die Messung wiederholen.',
      camera_provides_no_frames: 'Es ist ein Sensor-Fehler aufgetreten. Bitte den Reset des Scanners abwarten und dann die Messung wiederholen.',
      meshlab_subdivision_surface_fails: 'Bitte den Scanner verlassen, die Glasplatte reinigen, Hosenbeine evtl. erneut hochkrempeln, den Scanner wieder betreten und die Messung wiederholen.',
      meshlab_error: 'Bitte den Scanner verlassen, die Glasplatte reinigen, Hosenbeine evtl. erneut hochkrempeln, den Scanner wieder betreten und die Messung wiederholen.',
      foot_length_unexpected: 'Bitte den Scanner verlassen, die Glasplatte reinigen, Hosenbeine evtl. erneut hochkrempeln, den Scanner wieder betreten und die Messung wiederholen.',
      foot_width_unexpected: 'Bitte den Scanner verlassen, die Glasplatte reinigen, den Scanner wieder betreten und die Messung wiederholen.',
      glass_plate_covered: 'Bitte die Glasplatte freimachen.',
      foot_touches_bounding_volume: 'Bitte die Füße entsprechend der Markierung positionieren.',
    }
  },
  insole: {
    caption: 'Einlage',
    marketingText: 'Auf Grundlage der Fuß-Analyse empfehlen wir eine zusätzliche Unterstützung durch eine Einlage.\nSo werden Ermüdung und Überlastungen vermieden. Basierend auf Ihrem 3D Scan erstellen wir eine Einlage mit passender Längsgewölbehöhe individueller Pelotte zur Unterstützung des Vorderfußes.',
    q1caption: 'Haben Sie Schmerzen in den Füßen?',
    q2caption: 'Tragen Sie orthopädische Einlagen oder sind Sie aufgrund von Fußproblemen in orthopädischer Behandlung?',
    q3caption: 'Ergebnis',
    insoleTypeRequired: 'Typ der Einlegesohle ist erforderlich',
    sizeRequired: "Größe ist erforderlich!",
    recommendation: {
      button: 'KATALOG',
      0: {
        mainMessage: `Sie benötigen keine Einlage`,
        subMessage: 'Auf Grundlage Ihrer Fuß-Analyse haben wir festgestellt, dass Sie keine auffällige Fußfehlstellung haben.',
        conclusion: 'KEINE EINLAGE EMPFOHLEN'
      },
      1: {
        mainMessage: 'Bitte lassen Sie sich orthopädisch beraten',
        subMessage: 'Auf Grundlage Ihrer Fuß-Analyse empfehlen wir Ihnen eine ausführliche Begutachtung durch Ihren Orthopäden oder einen Orthopädie-Schuhmacher!',
        conclusion: 'INDIVIDUELLE EINLAGE ERFORDERLICH'
      },
    }
  },
  MeasurementScale: {
    foot_type: 'Fußtyp',
    heel_angle: 'Fersenwinkel',
    arch_height: 'Gewölbehöhe',
    foot_width: 'Fußbreite',
    flat: 'Flach',
    normal: 'Normal',
    high: 'Hoch',
    low: 'Flach',
    pronation: 'Pronation',
    neutral: 'Neutral',
    supination: 'Supination',
    narrow: 'Schmal',
    wide: 'Breit',
    millimeters: 'Millimeter',
    cm: 'Zentimeter',
    length: 'Länge',
    width: 'Breite',
    left: 'Links',
    right: 'Rechts',
    heightInstepPoint: 'Höhe Spann',
    widthHeel: "Breite Ferse"
  },
  Order: {
    confirmMessage: 'Soll der ausgewählte Schuh bestellt werden',
    successMessage: 'Die Bestellung wurde erfolgreich übermittelt!',
    failureMessage: 'Leider konnte die Bestellung nicht verarbeitet werden.',
    cotaFailureMessage: 'Sie haben Ihr Bestellimit erreicht, Sie können diesen Schuh nicht bestellen.',
    success: 'Vielen Dank',
  },
  CustomiseProduct: {
    confirmCustomisation: 'Möchten Sie diese Schuhe individualisieren?',
    weight: {
      'upto65Kg': 'bis 65 Kg',
      'from110Kg': 'ab 110 Kg'
    },
    activity: {
      mostlyStanding: 'Überwiegend Stehend',
      mostlyWalking: 'Überwiegend Gehend',
      mostlySitting: 'Überwiegend Sitzend'
    }
  },
  SafetyCatalog: {
    'title': "Sicherheits Katalog",
    'work shoe': 'Arbeitsschuhe',
    'work boot': 'Arbeitsstiefel',
    'work sandal': 'Arbeitssandalen',
  },
  RunningCtalog: {
    title: 'Lauschuh Katalog',
    runningExperience: 'ERFAHRUNG',
    runningExperienceQuestion: 'Wie ist Deine Lauferfahrung?',
    occasionally: 'Gelegentlich (1-2x)',
    regularly: 'Regelmäßig (2-3x)',
    ambitious: 'Ambitioniert (>3x)',
    application: 'EINSATZ',
    applicationQuestion: 'Für welche Anwendung suchst Du Schuhe?',
    walking: 'Walking',
    competition: 'Wettkampf',
    training: 'Training',
    terrain: 'TERRAIN',
    terrainQuestion: 'Auf welchem Undergrund möchtest Du laufen?',
    trail: 'Trail/Offraod',
    road: 'Straße / Laufband',
    track: 'Laufbahn',
    knee: 'KNIEACHSE',
    kneeQuestion: 'Wie ist Deine Knieachse ausgerichtet?',
    concave: 'X-Bein (Valgus)',
    neutral: 'Neutral',
    convex: 'O-Bein (Varus)',
    heel: 'FERSENWINKEL',
    heelQuestion: 'Wie verhält sich Deine Ferse im Lauf?',
    pronation: 'Innen (Pronation)',
    neutralheel: 'Neutral',
    supination: 'Außen (Supination)',
    cushioning: 'DÄMPFUNG',
    cushioningQuestion: 'In welcher Altersgruppe bist du?',
    soft: 'Weich',
    hard: 'HART / Direkt',
    natural: 'Natural Running',
    normal: 'Normal',
    ageGroup: 'ALTERSGRUPPE',
    ageGroupQuestion: "Welche Altersgruppe?",
    kids: 'KINDER',
    adults: 'ERWACHSENE',
    noProducts: 'Keine Produkte',
    filterCriteriaMessage: 'Bitte ändere deine Suchkriterien oder ändere deine Auswahl im Filter.',
    resetFilter: 'Filter zurücksetzen'
  },
  SkiCatalog: {
    title: 'Ski Catalog', 
    skill: 'ERFAHRUNG',
    skillQuestion: "Wie erfahren bist Du?",
    beginner: 'Anfänger',
    intermediate: 'Fortgeschritten',
    expert: 'Experte',
    race: 'Wettkampf',
    terrain: 'TERRAIN',
    terrainQuestion: 'Wo willst Du fahren?',
    allmontain: 'All Mountain',
    piste: 'Piste',
    freeride: 'Free Ride',
    hard: 'Harte Piste',
    weight: 'Gewicht',
    weightQuestion: "Wie schwer bist Du?",
    upto60: 'bis 60 kg',
    '60to80': '60 bis 80 kg',
    '80to100': '80 bis 100 kg',
    morethan100: 'mehr als 100 kg',
    ageGroup: 'Altersgruppe',
    ageGroupQuestion: "Wie alt bist Du?",
    kids: 'Kids',
    adults: 'Erwachsen',
    noProducts: 'Keine Produkte',
    filterCriteriaMessage: 'Bitte ändere deine Suchkriterien oder ändere deine Auswahl im Filter.',
    resetFilter: 'Filter zurücksetzen'
  },
  FilterCatalog: {
    title: 'Filter Optionen',
    category: ' Kategorie',
    brand: 'Brand',
    color: 'Farbe',
    leahtherType: 'Leder Typ',
    underground: 'Terrain',
    support: 'Stütze',
    sale: 'Sale',
    showProducts: 'Zeige Produkte'
  },
  secondaryRecommendatiom: {
    yourSize: 'DEINE GRÖßE',
    message1: 'Deine Fußlänge ist zwischen zwei Größen, die Dir beide passen!',
    message2: 'Wenn Du Deine Schuhe eher eng trägst, wähle: ',
    message3: 'Wenn Du Deine Schuhe eher locker trägst, wähle: '
  },
  expiviConfigurator: "Schuh Konfigurator",
  advertisingOnefid: "OneFID - Füße scannen und Schuhe finden die passen"

};
